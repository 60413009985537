

































import { mapState } from 'vuex'

export default {
	name: 'TestInfo',

	computed: {
		...mapState('test', [ 'info' ])
	},

	methods: {
		start() {
			this.$router.push({ name: 'Test' })
		},
		back() {
			this.$router.go(-1)
		}
	}
}
